
//
export function getLocalStorage(key) {
  const value = window.localStorage.getItem(key)
  try {
    return JSON.parse(value)
  } 
  catch (error) {
    return value
  }
}

export function setLocalStorage(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export function removeLocalStorage(key) {
  window.localStorage.removeItem(key)
}
  
// 不支持内容含有表情字符
export function isEmoji(str = '') {
  const reg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g;
  return str.match(reg) && str.match(reg).length
}

export function validatePhone(rule, value,callback) {
  const reg =/^[1][3-9][0-9]{9}$/;
  if(value==""||value==undefined||value==null){
    callback();
  }else {
    if ((!reg.test(value)) && value != "") {
      callback(new Error("请输入正确的电话号码"));
    } else {
      callback();
    }
  }
}
export function isnumber(rule, value, callback){
  const regPos = /^\d+(\.\d+)?$/; //非负浮点数
  const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
  if(regPos.test(value) || regNeg.test(value)) {
	callback();
  } else {
	callback(new Error('请输入数字'));
  } 
}

  export function nonzero(rule, value, callback){
    const regPos = /^\d+(\.\d+)?$/; //非负浮点数
    const regNeg = /^([1-9]\d*|0)(\.\d+)?$/; //负浮点数 
    if(value>0 || value<0) {
  	  callback();
    } else {
  	  callback(new Error('不为0'));
    } 
  }
  
//时间格式化函数，此处仅针对yyyy-MM-dd hh:mm:ss 的格式进行格式化
export function dateFormat(time,type) {
    var date=new Date(time);
    var year=date.getFullYear();
    /* 在日期格式中，月份是从0开始的，因此要加0
     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
     * */
    var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
    var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
    var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
    var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
    var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
    //拼接
    var redate =''
	if(type=='date'){ 
		redate = year+"-"+month+"-"+day;
	}
	else{
		redate = year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
	}
	return redate
}
 
 