 import { createRouter, createWebHistory } from "vue-router";
const routerHistory = createWebHistory();
// 定义一些路由,每个路由都需要映射到一个组件。
const routes = [  
	{
	   path: "/businesscard",
	   name: "businesscard",
	   component: () => import('../view/businesscard.vue'),
	   meta:{
		 keepAlive: false
	   }
	},
	// {
	//    path: "/TermView",
	//    name: "TermView",
	//    component: () => import('../view/TermView.vue'),
	//    meta:{
	// 	 keepAlive: false
	//    }
	// },
	// {
	// 	path: "/Payment",
	// 	name: "Payment",
	// 	component: () => import('../view/Payment.vue'),
	// 	meta:{
	// 	 keepAlive: false
	//    }
	// },
	// {
	// 	path: "/OrderClass",
	// 	name: "OrderClass",
	// 	component: () => import('../view/OrderClass.vue'),
	// 	meta:{
	// 	 keepAlive: true
	//    }
	// },
	// {
	// 	path: "/OrderForGoods",
	// 	name: "OrderForGoods",
	// 	component: () => import('../view/OrderForGoods.vue'),
	// 	meta:{
	// 	 keepAlive: false
	//    }
	// },
	// {
	// 	path: "/ShoppingCart",
	// 	name: "ShoppingCart",
	// 	component: () => import('../view/ShoppingCart.vue'),
	// 	meta:{
	// 	 keepAlive: false
	//    }
	// },
	// {
	// 	path: "/MemberSattention",
	// 	name: "MemberSattention",
	// 	component: () => import('../view/MemberSattention.vue'),
	// 	meta:{
	// 	 keepAlive: false
	//    }
	// },
	// {
	// 	path: "/MemberChangePassword",
	// 	name: "MemberChangePassword",
	// 	component: () => import('../view/MemberChangePassword.vue'),
	// 	meta:{
	// 	 keepAlive: false
	//    }
	// },
	// {
	// 	path: "/MemberLogin",
	// 	name: "MemberLogin",
	// 	component: () => import('../view/MemberLogin.vue'),
	// 	meta:{
	// 	 keepAlive: true
	//    }
	// },
	// {
	// 	path: "/MemberInformation",
	// 	name: "MemberInformation",
	// 	component: () => import('../view/MemberInformation.vue'),
	// 	meta:{
	// 	 keepAlive: true
	//    }
	// },
	// {
	// 	path: "/register",
	// 	name: "register",
	// 	component: () => import('../view/register.vue'),
	// 	meta:{
	// 	 keepAlive: true
	//    }
	// },
	// {
	// 	path: "/OrderFood",
	// 	name: "OrderFood",
	// 	component: () => import('../view/OrderFood.vue'),
	// 	meta:{
	// 	 keepAlive: true
	//    }
	// },
	// {
	// 	path: "/Home",
	// 	name: "Home",
	// 	component: () => import('../view/Home.vue'),
	// 	meta:{
	// 	 keepAlive: true
	//    }
	// },
	// {
	// 	path: "/memberHome",
	// 	name: "memberHome",
	// 	component: () => import('../view/memberHome.vue'),
	// 	meta:{
	// 	 keepAlive: false
	//    }
	// },
	{
		path: "/ProductsPageList",
		name: "ProductsPageList",
		component: () => import('../view/ProductsPageList.vue'),
		meta:{
		 keepAlive: false
	   }
	},
	// {
	// 	path: "/ProductDetail",
	// 	name: "ProductDetail",
	// 	component: () => import('../view/ProductDetail.vue'),
	// 	meta:{
	// 	 keepAlive: false
	//    }
	// },
	// {
	// 	path: "/ProductAboveAndBelowView",
	// 	name: "ProductAboveAndBelowView",
	// 	component: () => import('../view/ProductAboveAndBelowView.vue'),
	// 	meta:{
	// 	 keepAlive: true
	//    }  
	// },
	// {
	// 	path: "/ProductTwoFiguresInOneLineView",
	// 	name: "ProductTwoFiguresInOneLineView",
	// 	component: () => import('../view/ProductTwoFiguresInOneLineView.vue'),
	// 	meta:{
	// 	 keepAlive: true
	//    }  
	// },
	// {
	// 	path: "/ProductNomaplistView",
	// 	name: "ProductNomaplistView",
	// 	component: () => import('../view/ProductNomaplistView.vue'),
	// 	meta:{
	// 	 keepAlive: true
	//    }  
	// },
	// {
	// 	path: "/DynamicDetail",
	// 	name: "DynamicDetail",
	// 	component: () => import('../view/DynamicDetail.vue'),
	// 	meta:{
	// 	 keepAlive: true
	//    }  
	// },
   {
		path: "/DynamicAboveAndBelowView",
		name: "DynamicAboveAndBelowView",
		component: () => import('../view/DynamicAboveAndBelowView.vue'),
		meta:{
		 keepAlive: true
	   } 
   },
   // {
   // 		path: "/DynamicLeftPictureAndRightTextView",
   // 		name: "DynamicLeftPictureAndRightTextView",
   // 		component: () => import('../view/DynamicLeftPictureAndRightTextView.vue'),
   // 		meta:{
   // 		 keepAlive: true
   // 	   } 
   // },
   {
   		path: "/ClassIficationView",
   		name: "ClassIficationView",
   		component: () => import('../view/ClassIficationView.vue') 
   },
   {
   		path: "/SinglepageView",
   		name: "SinglepageView",
   		component: () => import('../view/SinglepageView.vue'),
   		meta:{
   		 keepAlive: true
   	   }  
   },
   {
      path: "/Index",
      name: "Index",
      component: () => import('../view/Index.vue')
   }, 
 ]; 
const router = createRouter({
  history: routerHistory,
  routes,
});
//导出路由
export default router;
