<template>   
<dev class="body"> 
	<router-view v-slot="{ Component }" >
	   <keep-alive  >
	     <component :is="Component" :key="$route.fullPath" v-if="$route.meta.keepAlive"/>
	   </keep-alive>
	     <component :is="Component"  :key="$route.fullPath" v-if="!$route.meta.keepAlive"/>
	 </router-view> 
</dev>
</template>

<script >
import { reactive, ref, toRefs,onMounted,onUnmounted,nextTick,provide} from 'vue'  
import { useRoute, useRouter } from 'vue-router' 
import axios from '@/utils/axios' 
import { getLocalStorage,setLocalStorage,removeLocalStorage} from '@/utils'
import { commonConstant } from '@/appsettings'

export default {
  name: "App",
  setup()
  {
	 const noMenu = ['/Index']
	 const route = useRoute()
	 const router = useRouter()
     const isRouterAlive = ref(true);// 局部组件刷新 
     const react = reactive({
		  loading: false,
		  params:{
			   tenantid:'0'
		  }
    }) 
	const routerBefore = router.beforeEach((to, from, next) => {  
		//removeLocalStorage('classification') 
		var output = getLocalStorage('classification') 
		 if(output != null)
		 {  
			  // console.log('1人');
			if(to.path=="/")
			{   //console.log(9); 
			    //  console.log('2人');
				var  firstdata =output.find(item=>item.fatherid==="0")
				var  firstpath=firstdata.frontendurl;
				var  firstid=firstdata.id;
				var  firstname=firstdata.name;
				router.push({ path:firstpath, query: {id:firstid,name:firstname,firstname:firstname} })  
			}
			else 
			{   //console.log('res.data1',output);
			     //  console.log('3人');
				next()
			}
		} 
		else
		{
		      //加载租户信息 
		      axios.get('/api/kyofficialwebsite/classification/mobilelist?tenant_id=0').then(res => {  
		      	 if(res.status==200){
		      		  // console.log('res.data2',res);
		      		  if(res.data.success && res.data.data!=null)
		      		  {    	
		      			  setLocalStorage('classification',res.data.data)
		      			  var  firstdata =res.data.data.find(item=>item.fatherid==="0")
		      			  var  firstpath=firstdata.frontendurl;
		      			  var  firstid=firstdata.id;
		      			  var  firstname=firstdata.name;
		      			  router.push({ path:firstpath, query: {id:firstid,name:firstname,firstname:firstname} })
					 }
		      		  else
		      		  {  
		      			  removeLocalStorage('classification')
		      		  }
		      	 } 
		      }) 
		}
	})
	onUnmounted(() => {
	  routerBefore()
	})
	 
	// // 局部组件刷新
	//  const reload = () => { 
	//       isRouterAlive.value = false; 
	//       nextTick(() => { 
	//          isRouterAlive.value = true; 
	//       });
	//     };
	//     provide("reload", reload);
	// // 局部组件刷新 end 
	 
	return {
	 ...toRefs(react), 
	   routerBefore,
	   isRouterAlive 
	}
  }
};
</script>


<style scoped>
	  
</style> 