// 公共常量
export const commonConstant = {
		 cmsapi_baseUrl: '/cmsapi',
		 fileapi_baseUrl: '/fileapi',
		 officialwebsiteapi_baseUrl: '/officialwebsiteapi',
		 placean_orderapi_baseUrl: '/placean_orderapi',
		 column:{ 
		 		 index:{ 
					 url:'/index',
					 name: '主页',
		         },
				 memberHome:{
					 url:'/memberHome',
					 name: '我的',
				 },
				 register:{
					 url:'/register',
					 name: '注册',
				 },
				 MemberLogin:{
					 url:'/MemberLogin',
					 name: '登录',
				 }, 
				 MemberInformation:{
					 url:'/MemberInformation',
					 name: '用户基本信息',
				 },
				 MemberChangePassword:{
					 url:'/MemberChangePassword',
					 name: '修改密码',
				 },
				 outlogin:'退出登录',
				 LogOff:'注销账号',
		 },
		 OrderState:{
			 unpaid:'unpaid',
			 name: '主页',
		 },
}