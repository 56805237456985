import { createApp } from 'vue'
import App from './App.vue'
 
const app = createApp(App);
 //公共样式
 import  "@/utils/base.css";
 
 //路由
 import router from "@/router/index.js";
 app.use(router);

import vant from 'vant'; 
import 'vant/lib/index.css';
app.use(vant);

import { Lazyload } from 'vant';
app.use(Lazyload);

import { BackTop } from 'vant';
app.use(BackTop);

import { ImagePreview } from 'vant';
app.use(ImagePreview);

import { Image as VanImage } from 'vant';
app.use(VanImage);


import { Icon } from 'vant';
app.use(Icon);

import { Tabbar, TabbarItem } from 'vant';
app.use(Tabbar);
app.use(TabbarItem);

//下拉
import { List } from 'vant';
app.use(List);

import { ShareSheet } from 'vant';
app.use(ShareSheet);

//上拉
import { PullRefresh } from 'vant';
app.use(PullRefresh);

//遮罩层
import { Overlay } from 'vant'
app.use(Overlay);

import { Search } from 'vant';
app.use(Search);

import { Tab, Tabs } from 'vant';
app.use(Tab);
app.use(Tabs);

import { Sidebar, SidebarItem } from 'vant';
app.use(Sidebar);
app.use(SidebarItem);

import { Col, Row } from 'vant';
app.use(Col);
app.use(Row);

import { Form, Field, CellGroup } from 'vant';
app.use(Form);
app.use(Field);
app.use(CellGroup);
  
import { Divider } from 'vant';
app.use(Divider);
  
app.mount('#app')
